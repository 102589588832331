<template>
  <page-header-wrapper>
    <div class="a-card">
      <MemberSearch @handleAdd="handleAdd" :queryParam="queryParam" />
      <div class="table-operator">
        <a-button
          type="dashed"
          style="display: none"
          @click="tableOption"
        >{{ (optionAlertShow && '关闭') || '开启' }} alert
        </a-button
        >
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :rowSelection="options.rowSelection"
        :row-key="(record) => record.memberId"
        :pagination="{showSizeChanger: true, showQuickJumper: true, pageSize:20, total: 50}"
        :scroll="{ x: true}"
      >
        <a-table-column :title="$t('columnName.remarkNo')" align="center">
          <template slot-scope="text, record, index">{{index+1}}</template>
        </a-table-column>
         <a-table-column title="操作" align="center">
          <span slot-scope="record">
             <a @click="handleDetail(record)">{{ $t("button.detail") }}</a>
          <!-- <a @click="handleEdit(record)" vv-if="$auth('table.member.edit')">{{ $t("button.edit") }}</a> -->
          <!-- <a-divider type="vertical"/> -->
            <a-dropdown v-if="0">
              <a class="ant-dropdown-link"> 更多 <a-icon type="down"/> </a>
              <a-menu slot="overlay">
                <a-menu-item  v-if="record.status!==0">
                  <a @click="handleDisable(record)">{{ $t('button.lock') }}</a>
                </a-menu-item>
                <a-menu-item v-if="record.status===0">
                  <a @click="handleEnable(record)">{{ $t('button.unlock') }}</a>
                </a-menu-item>
              <!--            <a-menu-item vv-if="$auth('table.member.delete')">-->
              <!--              <a @click="handleDelete(record)">{{ $t('button.delete') }}</a>-->
              <!--            </a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table-column>
        <!-- 会员账号 -->
        <a-table-column :title="$t('columnName.memberId')" align="left">
          <template slot-scope="record">{{record.memberId}}</template>
        </a-table-column>
        <!-- 会员昵称 -->
        <a-table-column :title="$t('columnName.memberNickName')" align="left">
          <template slot-scope="record">{{record.memberNickName}}</template>
        </a-table-column>
        <!-- 账户余额 -->
        <a-table-column :title="$t('columnName.balanceInfoList')" align="right">
          <template slot-scope="record">
            <span v-for="(item, idx) in record.balanceInfoList" :key="idx">
              {{ item.currentType }}: {{ formatTypeMoney(item.totalBalance, item.currentType,item.moneyMultiple) }} <br/>
            </span>
          </template>
        </a-table-column>
        <!-- 手机 -->
        <a-table-column :title="$t('columnName.mobile')" align="left">
          <template slot-scope="record">{{record.mobile}}</template>
        </a-table-column>
        <!-- 邮箱 -->
        <a-table-column :title="$t('columnName.mail')" align="left">
          <template slot-scope="record">{{record.mail}}</template>
        </a-table-column>
        <!-- 创建时间 -->
        <a-table-column :title="$t('columnName.createTime')" align="center">
          <template slot-scope="record">{{formatDateTime(record.createTime)}}</template>
        </a-table-column>  


        <div slot="datetime" slot-scope="text">
          {{ formatDateTime(text) }}
        </div>

        <div slot="balanceInfo" slot-scope="text">
          <span v-for="(item, idx) in text" :key="idx">
            {{ item.currentType }}: {{ formatTypeMoney(item.totalBalance, item.currentType,item.moneyMultiple) }} <br/>
          </span>
        </div>

        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <!-- 操作 -->
        <span slot="action" slot-scope="text, record">
          <a vv-if="$auth('table.spu.detail')" @click="handleDetail(record)">{{ $t("button.detail") }}</a>
          <!-- <a @click="handleEdit(record)" vv-if="$auth('table.member.edit')">{{ $t("button.edit") }}</a> -->
          <!-- <a-divider type="vertical"/> -->
          <a-dropdown v-if="0">
            <a class="ant-dropdown-link"> 更多 <a-icon type="down"/> </a>
            <a-menu slot="overlay">
              <a-menu-item vv-if="$auth('table.member.disable')" v-if="record.status!==0">
                <a @click="handleDisable(record)">{{ $t('button.lock') }}</a>
              </a-menu-item>
              <a-menu-item vv-if="$auth('table.member.enable')" v-if="record.status===0">
                <a @click="handleEnable(record)">{{ $t('button.unlock') }}</a>
              </a-menu-item>
            <!--            <a-menu-item vv-if="$auth('table.member.delete')">-->
            <!--              <a @click="handleDelete(record)">{{ $t('button.delete') }}</a>-->
            <!--            </a-menu-item>-->
            </a-menu>
          </a-dropdown>
        </span>
      </s-table>

      <a-modal
        width="50%"
        :title="$t('page.member')"
        :visible="memberModalShow"
        :confirm-loading="confirmEditLoading"
        @ok="handleEditOk"
        @cancel="handleEditCancel"
      >
        <MemberEdit :memberData="memberData"/>
      </a-modal>
      <a-drawer
        :closable="false"
        :visible="showDetailDrawer"
        placement="right"
        width="80%"
        @close="onDetailDrawerClose">
        <MemberDetail ref="memberDetail" :options="detailOptions"></MemberDetail>
      </a-drawer>
      <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk"/>
    </div>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  deleteMember,
  disableMember,
  enableMember,
  getMemberData,
  saveMemberData,
  searchMember
} from '@/api/member/member'
import moment from 'moment'
import { STable } from '@/components'

import MemberSearch from '@/views/member/MemberSearch'
import MemberEdit from '@/views/member/components/MemberEdit'
import MemberDetail from '@/views/member/MemberDetail'
import CommentEditor from '@/components/comment/CommentEditor'

export default {
  name: 'MemberList',
  components: {
    CommentEditor,
    STable,
    MemberSearch,
    MemberEdit,
    MemberDetail
  },
  data () {
    return {
      confirmEditLoading: false,

      showDetailDrawer: false,
      // 查询参数
      queryParam: { memberStatus: [] },
      data: [],
      status: 'all',
      detailOptions: {},
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getMember(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      memberModalShow: false,
      memberData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted () {
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchMember(Object.assign(parameter, this.queryParam)).then((res) => {
        console.log('loadData->searchMember.parameter', parameter, res)
        return res
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleDetail (record) {
      this.detailOptions = {
        memberId: record.memberId
      }
      this.showDetailDrawer = true
      this.$nextTick(() => {
        this.$refs.memberDetail.loadData({})
      })
    },
    onDetailDrawerClose () {
      this.showDetailDrawer = false
    },
    handleAdd (record) {
      // 弹出编辑页面
      this.memberData = {}
      this.memberModalShow = true
    },

    handleEdit (record) {
      const that = this
      getMemberData({ memberId: record.memberId })
        .then((res) => {
          that.memberData = res
        })
        .catch((res) => {
          that.$message.error(this.$t('entity.member.get.fail'))
        })
      // 弹出编辑页面
      this.memberModalShow = true
    },
    handleEditCancel (record) {
      // 弹出编辑页面
      this.memberModalShow = false
    },
    handleEditOk (record) {
      const that = this
      saveMemberData(this.memberData)
        .then((res) => {
          that.$message.success(this.$t('entity.member.save.success'))
          this.$refs.table.refresh(true)
        })
        .catch((res) => {
          that.$message.error(this.$t('entity.member.save.fail'))
        })
      this.memberData = {}
      // 弹出编辑页面
      this.memberModalShow = false
    },

    handleDelete (record) {
      const that = this
      this.$confirm(this.$t('message.confirm.delete'), this.$t('message.information'), {
        customClass: 'message_wrap',
        closeOnClickModal: false
      }).then(function () {
        return deleteMember(record)
          .then((res) => {
            console.log('handleDelete->delete{domain_name}', record, res)
            that.$message.success(this.$t('entity.member.delete.success'))
            return res
          })
          .catch(() => {
            that.$message.error(this.$t('entity.member.delete.fail'))
          })
      })
    },
    handleCommentEditOk (params) {
      console.info('===params:', params)
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableMember(record)
          .then(res => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          }).catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('锁定失败')
          })
      } else if (params.type === 'enable') {
        return enableMember(record)
          .then(res => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解锁定成功')
            return res
          }).catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDisable (record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable (record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },

    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
