<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <div>
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
      <a-form-item
        :label="$t('field.memberAccount')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'memberData.memberAccount',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.memberAccount') },
                           { max: 100, message: this.$t('field.memberAccount')+'长度小于等于100' }]}
          ]"
          name="memberData.memberAccount"
          v-model="memberData.memberAccount"
          :placeholder="'请输入'+ this.$t('field.memberAccount')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.memberNickName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'memberData.memberNickName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.memberNickName') },
                           { max: 100, message: this.$t('field.memberNickName')+'长度小于等于100' }]}
          ]"
          name="memberData.memberNickName"
          v-model="memberData.memberNickName"
          :placeholder="'请输入'+ this.$t('field.memberNickName')"/>
      </a-form-item>

      <a-form-item
        :label="$t('field.phone')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'memberData.phone',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.phone') },
                           { max: 100, message: this.$t('field.phone')+'长度小于等于100' }]}
          ]"
          name="memberData.phone"
          v-model="memberData.phone"
          :placeholder="'请输入'+ this.$t('field.phone')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.mail')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'memberData.mail',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.mail') },
                           { max: 100, message: this.$t('field.mail')+'长度小于等于100' }]}
          ]"
          name="memberData.mail"
          v-model="memberData.mail"
          :placeholder="'请输入'+ this.$t('field.mail')"/>
      </a-form-item>

      <a-form-item
        :label="$t('field.country')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-select mode="default" v-model="memberData.country">
          <a-select-option
            v-for="(item, index) in codeList.country"
            :key="index"
            :value="item.value">{{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        :label="$t('field.state')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'memberData.state',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.state') },
                           { max: 100, message: this.$t('field.state')+'长度小于等于100' }]}
          ]"
          name="memberData.state"
          v-model="memberData.state"
          :placeholder="'请输入'+ this.$t('field.state')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.memberGradeName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'memberData.memberGradeName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.memberGradeName') },
                           { max: 100, message: this.$t('field.memberGradeName')+'长度小于等于100' }]}
          ]"
          name="memberData.memberGradeName"
          v-model="memberData.memberGradeName"
          :placeholder="'请输入'+ this.$t('field.memberGradeName')"/>
      </a-form-item>

      <a-form-item
        :label="$t('field.isLeader')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'memberData.isLeader',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.isLeader') },
                           { max: 100, message: this.$t('field.isLeader')+'长度小于等于100' }]}
          ]"
          name="memberData.isLeader"
          v-model="memberData.isLeader"
          :placeholder="'请输入'+ this.$t('field.isLeader')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.stars')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'memberData.stars',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.stars') },
                           { max: 100, message: this.$t('field.stars')+'长度小于等于100' }]}
          ]"
          name="memberData.stars"
          v-model="memberData.stars"
          :placeholder="'请输入'+ this.$t('field.stars')"/>
      </a-form-item>

    </a-card>
  </div>
</template>

<script>
export default {
  name: 'EditMember',
  props: {
    memberData: {
      type: Object
    }
  },
  data () {
    return {
      codeList: {
        'country': [],
        'isLeader': [],
        'isValid': []
      }
    }
  },
  methods: {}
}

</script>
<style>

</style>
