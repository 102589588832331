<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="24">
        <!-- 会员账号 -->
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.memberId')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            :required="false"
          >
            <a-input
              v-decorator="[
                'queryParam.memberId',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.memberId') },
                    { max: 100, message: this.$t('field.memberId') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.memberId"
              v-model="queryParam.memberId"
              :placeholder="'请输入' + this.$t('field.memberId')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.memberNickName')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.memberNickName',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.memberNickName') },
                    { max: 100, message: this.$t('field.memberNickName') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.memberNickName"
              v-model="queryParam.memberNickName"
              :placeholder="'请输入' + this.$t('field.memberNickName')"
            />
          </a-form-item>
        </a-col>
        <!-- 会员状态 -->
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.memberStatus')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              :allowClear="true"
              mode="multiple"
              v-model="queryParam.memberStatus"
              :placeholder="'请选择' + this.$t('field.memberStatus')"
            >
              <a-select-option v-for="(item, index) in codeList.memberStatus" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.mobile')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.mobile',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.mobile') },
                    { max: 100, message: this.$t('field.mobile') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.mobile"
              v-model="queryParam.mobile"
              :placeholder="'请输入' + this.$t('field.mobile')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.mail')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.mail',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.mail') },
                    { max: 100, message: this.$t('field.mail') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.mail"
              v-model="queryParam.mail"
              :placeholder="'请输入' + this.$t('field.mail')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24" :sm="24">
          <span
            :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
            class="table-page-search-submitButtons"
          >
            <a-button icon="search" type="primary" @click="$parent.$parent.$refs.table.refresh(true)">{{
              $t('button.search')
            }}</a-button>
            <a-divider type="vertical" />
            <a-button
              style="margin-left: 8px"
              @click="
                () => {
                  $parent.$parent.queryParam = {}
                  $nextTick(() => {
                    $parent.$parent.$refs.table.refresh(true)
                  })
                }
              "
              >{{ $t('button.reset') }}</a-button
            >
            <a style="margin-left: 8px" @click="toggleAdvancedSearch">
              {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
              <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
            </a>
          </span>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'MemberSearch',
  components: {},
  props: {
    // 查询参数
    // eslint-disable-next-line vue/require-default-prop
    queryParam: {
      type: Object
    }
  },
  data() {
    return {
      showAdvancedSearchCondition: false,
      codeList: {
        country: [],
        memberGradeCode: [],
        registerType: [],
        isLeader: [],
        memberStatus: [
          { label: '有效', value: '有效' },
          { label: '无效', value: '无效' },
          { label: '已锁定', value: '已锁定' },
          { label: '已退会', value: '已退会' }
        ]
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    toggleAdvancedSearch() {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
