<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-descriptions title="" bordered>
        <a-descriptions-item label="会员ID">{{ data.memberId }}</a-descriptions-item>
        <a-descriptions-item label="会员昵称">{{ data.memberNickName }}</a-descriptions-item>
        <a-descriptions-item label="会员照片">
          <img
            :src="data.memberAvatarUrl"
            width="100"
            height="100"/></a-descriptions-item>
        <a-descriptions-item
          label="
            手机
            ">{{ data.mobile }}
        </a-descriptions-item>
        <a-descriptions-item label="邮箱">{{ data.mail }}</a-descriptions-item>
        <a-descriptions-item label=""></a-descriptions-item>
        <!-- <a-descriptions-item label="AppStore账号">{{ data.appStoreAccount }}</a-descriptions-item> -->
        <!-- <a-descriptions-item label="PlayStore账号">{{ data.playStoreAccount }}</a-descriptions-item> -->
        <a-descriptions-item label="IOS设备编号">{{ data.iosDeviceNo }}</a-descriptions-item>
        <a-descriptions-item label="安卓设备编号">{{ data.androidDeviceNo }}</a-descriptions-item>
        <!-- <a-descriptions-item label="浏览器设备编号">{{ data.browserDeviceNo }}</a-descriptions-item>
        <a-descriptions-item label="国家">{{ data.country }}</a-descriptions-item>
        <a-descriptions-item label="州/省">{{ data.state }}</a-descriptions-item>
        <a-descriptions-item label="会员级别编码">{{ data.memberGradeCode }}</a-descriptions-item>
        <a-descriptions-item label="会员等级名称">{{ data.memberGradeName }}</a-descriptions-item> -->
        <a-descriptions-item label="注册类型">{{ data.registerType }}</a-descriptions-item>
        <!-- <a-descriptions-item label="是否团长">{{ getCode('isLeader', data.isLeader) }}</a-descriptions-item>
        <a-descriptions-item label="订单总金额">{{ data.totalAmount }}</a-descriptions-item>
        <a-descriptions-item label="优惠总金额">{{ data.totalDiscountAmount }}</a-descriptions-item>
        <a-descriptions-item label="总数量">{{ formatCount(data.totalQuantity) }}</a-descriptions-item>
        <a-descriptions-item label="销售单总数">{{ formatCount(data.totalSaleOrderCount) }}</a-descriptions-item>
        <a-descriptions-item label="服务单总数">{{ formatCount(data.totalServiceOrderCount) }}</a-descriptions-item>
        <a-descriptions-item label="参团总数量">{{ formatCount(data.totalGroupByCount) }}</a-descriptions-item>
        <a-descriptions-item label="拼团活动总数量">{{ formatCount(data.totalGroupCount) }}</a-descriptions-item>
        <a-descriptions-item label="星级">{{ data.stars }}</a-descriptions-item>
        <a-descriptions-item label="状态">{{ getCode('memberStatus', data.memberStatus) }}</a-descriptions-item> -->
      </a-descriptions>
      <br>

      <div>
        <a-table
          v-if="data && data.balanceInfoList"
          :columns="balanceInfoListColumns"
          :dataSource="data.balanceInfoList"
          :bordered="true"
          :row-key="(record, index) => index"
          :pagination="false">
          <div slot="money" slot-scope="text,record">{{ formatTypeMoney(text, record.currentType,record.moneyMultiple) }} </div>
          <!-- <div slot="money" slot-scope="text"> {{ formatMoney(text, 2, '.', ',') }}</div> -->
        </a-table>
      </div>

      <br />

      <a-descriptions bordered title="">
        <!-- <a-descriptions-item label="更新人编码">{{ data.updaterCode }}</a-descriptions-item>
        <a-descriptions-item label="更新人">{{ data.updaterName }}</a-descriptions-item> -->
        <a-descriptions-item label="更新时间">{{ formatDateTime(data.updateTime) }}</a-descriptions-item>
      </a-descriptions>
    </a-card>
  </page-header-wrapper>

</template>

<script>
import { STable } from '@/components'
import { getMemberDetail } from '@/api/member/member'

export default {
  name: 'MemberDetail',
  components: {
    STable
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    options: {
      type: Object
    }
  },
  created () {
    // getRoleList({t: new Date()})
  },
  mounted () {
    // this.loadData({})
  },
  data () {
    const data = {
      'queryParam': {},
      'data': {},
      balanceInfoListColumns: [
        {
          defaultTitle: '货币类型',
          title: this.$t('columnName.currentType'),
          dataIndex: 'currentType',
          key: 'currentType',
          align: 'left'
        },
        {
          defaultTitle: '账户总额',
          title: this.$t('columnName.totalBalance'),
          dataIndex: 'totalBalance',
          key: 'totalBalance',
          align: 'left',
          scopedSlots: {
            customRender: 'money'
          }
        }

      ]
    }
    data.codeList = {
      'country': [],
      'registerType': [],
      'isLeader': [],
      'status': [],
      'isValid': []
    }

    return data
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      getMemberDetail(Object.assign(parameter, { memberId: this.options.memberId }))
        .then(res => {
          console.log('loadData->getMemberDetail.parameter', parameter, res)
          this.data = res // this.data = res.data
          return this.data
        })
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        'processing': '进行中',
        'success': '完成',
        'failed': '失败'
      }
      return statusMap[status]
    }
  },
  computed: {
    title () {
      return this.$route.meta.title
    }
  }

}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
