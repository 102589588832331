import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveMemberData (parameter) {
  let url = baseUrl + '/member/member/save'
  if (!parameter.memberId) {
    url = baseUrl + '/member/member/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchMember (parameter) {
  console.log('parameter', parameter)
  return request({
    url: baseUrl + '/member/member/search?' + qs.stringify(parameter, { indices: false }),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listMember (parameter) {
  return request({
    url: baseUrl + '/member/member/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}

export function getMemberData (parameter) {
  return request({
    url: baseUrl + '/member/member/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableMember (parameter) {
  return request({
    url: baseUrl + '/member/member/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableMember (parameter) {
  return request({
    url: baseUrl + '/member/member/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteMember (parameter) {
  return request({
    url: baseUrl + '/member/member/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function getMemberDetail (parameter) {
  return request({
    url: baseUrl + '/member/member/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
